import { render } from "./userset.vue?vue&type=template&id=4c5942d4&scoped=true"
import script from "./userset.vue?vue&type=script&lang=js"
export * from "./userset.vue?vue&type=script&lang=js"

import "./userset.vue?vue&type=style&index=0&id=4c5942d4&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4c5942d4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4c5942d4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4c5942d4', script)) {
    api.reload('4c5942d4', script)
  }
  
  module.hot.accept("./userset.vue?vue&type=template&id=4c5942d4&scoped=true", () => {
    api.rerender('4c5942d4', render)
  })

}

script.__file = "src/views/my/userset/userset.vue"

export default script